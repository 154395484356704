addEventListener('DOMContentLoaded', _ => {
    Object.defineProperty(document, 'top', {
        get() {
            return (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0)
        },
    })
    const navElement = document.querySelector('#navigation')
    const nextSibling = navElement.parentElement.querySelector('#navigation + *')
    const mirror = navElement.cloneNode(true)
    mirror.classList.add('mirror')
    mirror.style.display = 'none';
    navElement.parentElement.insertBefore(mirror, nextSibling)
    addEventListener('scroll', _ => {
        if (document.top > navElement.clientHeight + 50) {
            navElement.classList.add('scrolled')
        } else {
            navElement.classList.remove('scrolled')
        }
    })

    const hamburger = document.querySelector('#nav-toggle')
    const navItems = navElement.querySelectorAll('.navigation-items');
    const navItemsWrapper = navElement.querySelector('#navigation-items-wrapper');

    onResize();

    addEventListener('resize', onResize);

    hamburger.addEventListener('click', _ => {
        navItemsWrapper.classList.toggle('open')
        hamburger.classList.toggle('opened')
    });

    const dropdowns = navElement.querySelectorAll('.dropdown');
    dropdowns.forEach(dropdown => {
        $(dropdown).on('show.bs.dropdown', _ => {
            const navToggle = navElement.querySelector('#nav-toggle');
            navToggle.classList.add('d-none');
        });
        $(dropdown).on('hide.bs.dropdown', _ => {
            const navToggle = navElement.querySelector('#nav-toggle');
            navToggle.classList.remove('d-none');
        });
    });

    const heroVideo = document.querySelector('#hero-video');
    if (heroVideo) {
        heroVideo.style.opacity = 0;
        
        heroVideo.addEventListener('canplay', function() {
            setTimeout(function() {
                fade(heroVideo);
            }, 300);
        });

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    heroVideo.play();
                } else {
                    heroVideo.pause();
                }
            });
        });
        observer.observe(heroVideo);
    }

    window.onload = _ => {
        mirror.style.display = '';
    }
})

function fade(element) {
    var op = 0;
    var timer = setInterval(function() {
        if (op >= 1) clearInterval(timer);
        element.style.opacity = op;
        element.style.filter = 'alpha(opacity=' + op * 100 + ")";
        op += op * 0.1 || 0.1;
    }, 15);
}

function onResize() {
    const navElement = document.querySelector('#navigation');
    const navItems = navElement.querySelectorAll('.navigation-items');
    const navItemsWrapper = navElement.querySelector('#navigation-items-wrapper');
    if (window.innerWidth < 1200) {
        navItems.forEach(item => {
            if (!navItemsWrapper.contains(item)) {
                navItemsWrapper.appendChild(item);
            }
        });
    } else {
        const firstHalfItemsWrapper = document.querySelector('.first-half-items-wrapper');
        const secondHalfItemsWrapper = document.querySelector('.second-half-items-wrapper');

        navItems.forEach(item => {
            if (item.classList.contains('first-half-items')) {
                if (!firstHalfItemsWrapper.contains(item)) {
                    firstHalfItemsWrapper.appendChild(item);
                }
            } else {
                if (!secondHalfItemsWrapper.contains(item)) {
                    secondHalfItemsWrapper.appendChild(item);
                }
            }
        });
    }
}
