document.addEventListener('DOMContentLoaded', function() {
    const printHomeDesignForm = document.querySelector('form[print-home-design]');
    if (printHomeDesignForm) {
        printHomeDesignForm.addEventListener('submit', function(e) {
            e.preventDefault();
            const submitBtn = e.target.querySelector('button[type="submit"]');
            const btnText = submitBtn.querySelector('.btn-text');
            const spinner = submitBtn.querySelector('.spinner-border');
            const printIcon = submitBtn.querySelector('.print-icon');

            submitBtn.setAttribute('disabled', '');
            printIcon.classList.add('d-none');
            spinner.classList.remove('d-none');
            btnText.classList.add('ml-2');
            btnText.innerHTML = 'Generating PDF...';

            const designName = e.target.getAttribute('data-home-design');
            const data = new FormData(printHomeDesignForm);
            const url = printHomeDesignForm.getAttribute('action');
            fetch(url, {
                method: 'POST',
                body: data
            })
            .then(response => {
                if (!response.ok) {
                    submitBtn.removeAttribute('disabled');
                    spinner.classList.add('d-none');
                    btnText.classList.remove('ml-2');
                    btnText.innerHTML = 'An error occurred, please try again later.';
                    throw new Error('Print Page: Network response was not ok');
                }
                return response;
            })
            .then(response => response.blob())
            .then(blob => {
                const fileURL = URL.createObjectURL(blob);
                const printWindow = window.open(fileURL, '_blank');
                if (!printWindow || printWindow.closed || typeof printWindow.closed === 'undefined') {
                    // Pop-up blocked by the browser
                    window.location.href = fileURL;
                    window.onload = function() {
                        window.print();
                    };
                } else {
                    printWindow.onload = function() {
                        setTimeout(function() {
                            printWindow.print();
                        }, 500);
                    };
                }

                submitBtn.removeAttribute('disabled');
                spinner.classList.add('d-none');
                printIcon.classList.remove('d-none');
                btnText.classList.remove('ml-2');
                btnText.innerHTML = 'Print Page';
            })
        });
    }
});