import { tns } from 'tiny-slider'
import { registerComponent } from '../components-front';

registerComponent('column-carousel', _ => {
    document.querySelectorAll('.column-carousel').forEach(carousel => {
        const cols = carousel.dataset.cols || 2
        const slider = tns({
            container: carousel,
            items: Math.max(2, Math.floor(cols / 2)),
            slideBy: 1,
            gutter: 50,
            controls: false,
            navPosition: 'bottom',
            arrowKeys: true,
            lazyload: true,
            responsive: {
                768: {
                    items: Math.max(3, Math.floor(cols * 0.75)),
                },
                992: {
                    items: cols,
                },
            },
        })
    })
})
