document.addEventListener('DOMContentLoaded', _ => {
    const imageGrids = document.querySelectorAll('.image-grid');
    if (imageGrids) {
        imageGrids.forEach(imageGrid => {
            $(imageGrid).lightGallery({
                selector: '.image-grid-image__gallery-item',
                download: false,
                zoom: false,
                fullScreen: false,
                thumbnail: true,
                share: false,
                autoplay: false,
                autoplayControls: false,
                videoMaxWidth: '1000px',
                videoMaxHeight: '562px',
                videojs: true,
                videojsOptions: {
                    fluid: true,
                    preload: 'auto',
                    controls: true,
                    responsive: true,
                    fill: true,
                    aspectRatio: '16:9',
                    autoplay: false,
                    loop: false,
                    muted: false,
                }
            });
        });
    }
})