document.addEventListener('DOMContentLoaded', function () {
    const urlParams = new URLSearchParams(window.location.search);
    const category = urlParams.get('category');
    const tag = urlParams.get('tag');
    const search = urlParams.get('search');
    const page = urlParams.get('page');
    
    if (category || tag || search || page) {
        const blogElement = document.querySelector('.blog');
        blogElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });

        const paginationLinks = document.querySelectorAll('.pagination a.page-link');
        if (paginationLinks) {
            paginationLinks.forEach(link => {
                const href = link.getAttribute('href');
                if (category) {
                    link.setAttribute('href', href + '&category=' + category);
                }
                if (tag) {
                    link.setAttribute('href', href + '&tag=' + tag);
                }
                if (search) {
                    link.setAttribute('href', href + '&search=' + search);
                }
            });
        }

        if (search) {
            document.querySelector('#searchBarSidebar').value = search;
        }

        if (category) {
            document.querySelectorAll('.cat-item').forEach(catItem => {
                if (catItem.getAttribute('href') == `?category=${category}`) {
                    catItem.classList.add('text-underline');
                }
            });
        }
    }
});

$(document).on('submit', '.search-form', function (e) {
    e.preventDefault();
    // var searchInput = $('#searchBar').val();
    var searchInput = $(this).find("input[name=search]").val();
    window.location.href = '/blog?search=' + encodeURIComponent(searchInput.replace(/[^a-z0-9áéíóúñü \.,_-]/gim,"").trim());
});