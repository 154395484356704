document.addEventListener('DOMContentLoaded', function() {
    const homeDesignSelects = document.querySelectorAll('.home-design-filter-select-elem');
    const homeDesignGrid = document.querySelector('.home-designs-grid');
    
    const searchBtn = document.querySelector('.home-design-search');
    const primaryListingSearchBtn = document.querySelector('.home-design-search--primary-listing');

    if (!homeDesignGrid) {
        return;
    }

    const homeDesigns = homeDesignGrid.querySelectorAll('.home-design-item');

    // HOME SEARCH CODE
    if (homeDesignSelects.length > 0) {
        const bedsSelected = new URLSearchParams(window.location.search).get('beds');
        const bathsSelected = new URLSearchParams(window.location.search).get('baths');
        const carsSelected = new URLSearchParams(window.location.search).get('cars');
        const minimumLandWidthSelected = new URLSearchParams(window.location.search).get('minimum_land_width');
        const squareMetresSelected = new URLSearchParams(window.location.search).get('square_metres');

        if (bedsSelected) {
            document.querySelector('.home-design-filter-select-elem--bed').value = bedsSelected;
        }
        if (bathsSelected) {
            document.querySelector('.home-design-filter-select-elem--baths').value = bathsSelected;
        }
        if (carsSelected) {
            document.querySelector('.home-design-filter-select-elem--cars').value = carsSelected;
        }
        if (minimumLandWidthSelected) {
            document.querySelector('.home-design-filter-select-elem--min-land-width').value = minimumLandWidthSelected;
        }
        if (squareMetresSelected) {
            document.querySelector('.home-design-filter-select-elem--square-metre').value = squareMetresSelected;
        }

        if (primaryListingSearchBtn) {
            primaryListingSearchBtn.addEventListener('click', ev => {
                ev.preventDefault();
                const selectedBeds = document.querySelector('.home-design-filter-select-elem--bed').value ?? "";
                const selectedBaths = document.querySelector('.home-design-filter-select-elem--baths').value ?? "";
                const selectedCars = document.querySelector('.home-design-filter-select-elem--cars').value ?? "";
                const selectedMinimumLandWidth = document.querySelector('.home-design-filter-select-elem--min-land-width').value ?? "";
                const selectedSquareMetres = document.querySelector('.home-design-filter-select-elem--square-metre').value ?? "";

                let url = new URL(window.location.href);
                if (selectedBeds) {
                    url.searchParams.set('beds', selectedBeds);
                } else {
                    url.searchParams.delete('beds');
                }
                if (selectedBaths) {
                    url.searchParams.set('baths', selectedBaths);
                } else {
                    url.searchParams.delete('baths');
                }
                if (selectedCars) {
                    url.searchParams.set('cars', selectedCars);
                } else {
                    url.searchParams.delete('cars');
                }
                if (selectedMinimumLandWidth) {
                    url.searchParams.set('minimum_land_width', selectedMinimumLandWidth);
                } else {
                    url.searchParams.delete('minimum_land_width');
                }
                if (selectedSquareMetres) {
                    url.searchParams.set('square_metres', selectedSquareMetres);
                } else {
                    url.searchParams.delete('square_metres');
                }

                url.hash = 'home-design-filter';

                window.location.href = url.href;
            });
        }
        if (searchBtn) {
            searchBtn.addEventListener('click', ev => {
                ev.preventDefault();
                homeDesigns.forEach(homeDesign => {
                    const beds = homeDesign.querySelector('[data-beds]').textContent;
                    const baths = homeDesign.querySelector('[data-baths]').textContent;
                    const cars = homeDesign.querySelector('[data-cars]').textContent;
                    const minimumLandWidth = homeDesign.querySelector('[data-minimum-land-width]').textContent;
                    const squareMetres = homeDesign.querySelector('[data-square-metre]').textContent;
                    const selectedBeds = document.querySelector('.home-design-filter-select-elem--bed').value ?? "";
                    const selectedBaths = document.querySelector('.home-design-filter-select-elem--baths').value ?? "";
                    const selectedCars = document.querySelector('.home-design-filter-select-elem--cars').value ?? "";
                    const selectedMinimumLandWidth = document.querySelector('.home-design-filter-select-elem--min-land-width').value ?? "";
                    const selectedSquareMetres = document.querySelector('.home-design-filter-select-elem--square-metre').value ?? "";
    
                    if (
                        (selectedBeds === "" || !beds || selectedBeds === beds) && 
                        (selectedBaths === "" || !baths || selectedBaths === baths) && 
                        (selectedCars === "" || !cars || selectedCars === cars) &&
                        (selectedMinimumLandWidth === "" || !selectedMinimumLandWidth || parseInt(selectedMinimumLandWidth) === 0 || parseInt(selectedMinimumLandWidth) <= parseInt(minimumLandWidth.replace(',', '').replace('mm', ''))) && 
                        (selectedSquareMetres === "" || !selectedSquareMetres || parseInt(selectedSquareMetres) === 0 || parseInt(selectedSquareMetres) >= parseInt(squareMetres.replace(',', '').replace('m2', '').replace('m²', '')))
                    ) {
                        homeDesign.classList.remove('d-none');
                    } else {
                        homeDesign.classList.add('d-none');
                    }
                });
    
                const visibleHomeDesigns = homeDesignGrid.querySelectorAll('.home-design-item:not(.d-none)');
                const noResultsMessage = document.querySelector('.home-design-no-results');
                if (visibleHomeDesigns.length === 0) {
                    noResultsMessage.classList.remove('d-none');
                } else {
                    noResultsMessage.classList.add('d-none');
                }

                if (window.innerWidth < 992) {
                    const mobileSearchConfirmation = document.querySelector('.mobile-search-confirmation');
                    mobileSearchConfirmation.classList.remove('search-confirmation-hidden');
                    setTimeout(() => {
                        mobileSearchConfirmation.classList.add('search-confirmation-hidden');
                    }, 10000);
                }
            });
        }
    }
});
// ADD HOVER EVENTS TO STOP HEIGHT FROM BEING DUMB
window.addEventListener('load', _=> {
    if(homeDesigns.length > 0) {
        //calibrateDesignMaxHeight(homeDesigns);
        window.addEventListener('resize', _=> {
            calibrateDesignMaxHeight(homeDesigns);
        })
    }

    function calibrateDesignMaxHeight(homeDesigns) {
        homeDesigns.forEach(design => {
            design.style.minHeight = 'unset';
            design.style.minHeight = `${design.clientHeight}px`;
        })
    }
})

// this whole function is a testament to my own hubris the foolishness of my over-engineering.
// document.addEventListener('DOMContentLoaded', _=> {
//     const homeDesigns = document.querySelectorAll('.home-design')
//     if(homeDesigns.length > 0) {
//         homeDesigns.forEach(design => {
//             design.addEventListener('mouseover', houseDesignHoverOver);
//         })
//
//         async function houseDesignHoverOver() {
//             var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
//             if(isSafari != true) {
//                 this.style.minHeight = `${this.clientHeight}px`;
//             }
//         }
//     }
// })