document.addEventListener('DOMContentLoaded', function() {
    const homeGalleryLibrary = document.querySelector('#home-gallery-library');
    if (homeGalleryLibrary === null) {
        return;
    }

    const macyInstance = window.Macy({
        container: '#home-gallery-library',
        trueOrder: false,
        waitForImages: false,
        columns: 3,
        margin: 16,
        breakAt: {
            1200: 3,
            940: 2,
            520: 1
        },
    });

    macyInstance.on(macyInstance.constants.EVENT_IMAGE_COMPLETE, function() {
        $(homeGalleryLibrary).lightGallery({
            selector: '.home-gallery-item',
            download: false,
            zoom: false,
            fullScreen: false,
            thumbnail: true,
            share: false,
            autoplay: false,
            autoplayControls: false,
            videoMaxWidth: '1000px',
            videoMaxHeight: '562px',
            videojs: true,
            videojsOptions: {
                fluid: true,
                preload: 'auto',
                controls: true,
                responsive: true,
                fill: true,
                aspectRatio: '16:9',
                autoplay: false,
                loop: false,
                muted: false,
            }
        });
    });

    // Features
    const features = document.querySelectorAll('.home-gallery-features');
    if (features) {
        features.forEach((featuresWrapper) => {
            let ul = featuresWrapper.querySelector('ul');

            ul.classList.add('col-12', 'col-md-6');

            let newUl = ul.cloneNode(false);
            let last = ul.children[Math.round((ul.children.length / 2) - 1)];

            ul.parentNode.insertBefore(newUl, ul.nextSibling);

            while(last.nextSibling) newUl.appendChild(last.nextSibling);
        });
    }
});