import { tns } from 'tiny-slider'
import { registerComponent } from '../components-front';

registerComponent('process-carousel', _ => {
    document.querySelectorAll('.process-carousel-wrapper').forEach(carouselWrapper => {
        const carousel = carouselWrapper.querySelector('.process-carousel')
        const cols = carousel.dataset.cols || 2

        /** @var {HTMLDivElement} prev */
        const prev = carouselWrapper.querySelector('.prev')
        /** @var {HTMLDivElement} next */
        const next = carouselWrapper.querySelector('.next')

        const slider = tns({
            container: carousel,
            items: Math.max(2, Math.floor(cols / 2)),
            slideBy: 1,
            gutter: 50,
            loop: false,
            nav: false,
            prevButton: prev,
            nextButton: next,
            arrowKeys: true,
            lazyload: true,
            responsive: {
                768: {
                    items: Math.max(3, Math.floor(cols * 0.75)),
                },
                992: {
                    items: cols,
                },
            },
        });

        const nextBtn = document.querySelector('.process-carousel-wrapper .next');
        const buildProcessBtn = document.querySelector('.process-carousel-wrapper .build-process-btn');

        if (nextBtn && nextBtn.style.display && nextBtn.style.display == 'none') {
            buildProcessBtn.classList.remove('d-none');
        }

        window.onresize = _ => {
            const nextBtn = document.querySelector('.process-carousel-wrapper .next');
            const buildProcessBtn = document.querySelector('.process-carousel-wrapper .build-process-btn');

            if (nextBtn && nextBtn.style.display && nextBtn.style.display == 'none') {
                buildProcessBtn.classList.remove('d-none');
            } else {
                buildProcessBtn.classList.add('d-none');
            }
        };
    })
})
